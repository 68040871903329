<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addMilestone']">新增里程碑</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="milestoneName" slot-scope="text, record">
          <div @click="$util.goHidden(authority.viewMilestoneDetail, `/milestone/milestoneDetail?id=${record.id}`, text)" class="text-underline">{{ text }}</div>
        </template>
        <template slot="milestoneStatus" slot-scope="text">
          <span v-if="text === 0">未开始</span>
          <span v-if="text === 1">进行中</span>
          <span v-if="text === 2">已完成</span>
          <span v-if="text === 3">已搁置</span>
          <span v-if="text === 4">已取消</span>
        </template>
        <template slot="milestoneProgressValue" slot-scope="text">
          <a-progress :percent="text || 0" />
        </template>
        <template slot="projectName" slot-scope="text, record">
          <div @click="$util.checkUrl(authority.viewProjectDetail, `/project/projectDetail?id=${record.projectId}`)" class="text-underline dominant">{{ text }}</div>
        </template>
        <template slot="closingDate" slot-scope="text, record">
          <div>{{ record.planEndDate }}</div>
          <div :class="color[record.milestoneProgress]">{{ text }}</div>
        </template>
        <template slot="closingDate" slot-scope="text, record">
          <div>{{ record.planEndDate }}</div>
          <div :class="color[record.milestoneProgress]">{{ text }}</div>
        </template>
        <template slot="actualDurationDays" slot-scope="text, record">
          <div>{{ record.planDurationDays }}</div>
          <div :class="color[record.milestoneProgress]">{{ text }}</div>
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm"
                      class="drawer-form">
          <a-form-model-item label="里程碑状态">
            <a-select v-model="filterForm.queryParam.milestoneStatus" placeholder="请选择里程碑状态">
              <a-select-option :value="0">未开始</a-select-option>
              <a-select-option :value="1">进行中</a-select-option>
              <a-select-option :value="2">已完成</a-select-option>
              <a-select-option :value="3">已搁置</a-select-option>
              <a-select-option :value="4">已取消</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="里程碑负责人">
            <a-select v-model="filterForm.queryParam.milestoneLeaderId" placeholder="请选择里程碑负责人">
              <a-select-option :value="item.id" v-for="(item, index) in principalList" :key="item.id">{{item.staffName}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="关联项目">
            <a-select v-model="filterForm.queryParam.projectId" placeholder="请选择关联项目">
              <a-select-option :value="item.id" v-for="(item, index) in projectList" :key="item.id">{{item.projectName }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="里程碑进度">
            <a-checkbox-group
                v-model="filterForm.queryParam.milestoneProgressList"
                name="checkboxgroup"
                :options="checkOptions"
            />
          </a-form-model-item>
          <a-form-model-item label="里程碑截止日期">
            <a-range-picker @change="rangeChange"/>
          </a-form-model-item>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="新增里程碑"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="里程碑名称" prop="milestoneName">
              <a-input placeholder="里程碑名称" v-model="form.milestoneName" />
            </a-form-model-item>
            <a-form-model-item label="里程碑状态" prop="milestoneStatus">
              <a-select v-model="form.milestoneStatus" placeholder="请选择里程碑状态">
                <a-select-option :value="0">未开始</a-select-option>
                <a-select-option :value="1">进行中</a-select-option>
                <a-select-option :value="2">已完成</a-select-option>
                <a-select-option :value="3">已搁置</a-select-option>
                <a-select-option :value="4">已取消</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="关联项目" prop="projectId">
              <a-select v-model="form.projectId" placeholder="请选择关联项目" @change="projectChange">
                <a-select-option :value="item.id" v-for="(item, index) in projectList" :key="item.id">{{ item.projectName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="里程碑负责人" prop="milestoneLeaderId">
              <a-select v-model="form.milestoneLeaderId" placeholder="请选择里程碑负责人" @change="forceUpdate">
                <template v-if="form.projectId">
                  <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{ item.staffName }}</a-select-option>
                </template>
                <template v-else>
                  <a-select-option :value="0" disabled>请先选择关联项目</a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="里程碑截止日期" prop="closingDate">
              <a-date-picker @change="dateChange" style="width: 100%"/>
            </a-form-model-item>
            <a-form-model-item label="里程碑描述" prop="remark">
              <a-textarea placeholder="请输入里程碑描述" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="关联产品参数"
        placement="right"
        :visible="linkShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="linkShow"
    >
      <a-form-model
          ref="form"
          labelAlign="right"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :model="form"
          :rules="rules"
      >
        <a-row>
          <a-form-model-item label="产品名称" prop="productName">
            <a-input placeholder="请输入产品名称" v-model="form.productName" />
          </a-form-model-item>
          <a-form-model-item label="产品ID" prop="productId">
            <a-input placeholder="请输入产品ID" v-model="form.productId" />
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remark">
            <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
          </a-form-model-item>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-form-model-item :wrapper-col="{ span: 18, offset: 6 }">
            <a-button type="primary" @click="onSubmit">确定</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </a-drawer>
    <a-drawer
        title="产品文件"
        placement="right"
        :visible="filesShow"
        @close="filesShow = false"
        width="500px"
        class="drawer"
        v-if="filesShow"
    >
      <a-row>

      </a-row>
    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getMilestoneList,
  createMilestone,
  deleteMilestone,
  getMilestonePrincipal
} from '@/api/milestone/milestone'
import { getProjectList } from '@/api/project/project'
import { getStaffList } from '../../api/humanResources/staff'
import { getProjectMember } from '../../api/task/task'
export default {
  name: 'milestone',
  data () {
    return {
      columns: [
        {
          title: '里程碑名称',
          dataIndex: 'milestoneName',
          scopedSlots: { customRender: 'milestoneName' }
        },
        {
          title: '状态',
          dataIndex: 'milestoneStatus',
          scopedSlots: { customRender: 'milestoneStatus' }
        },
        {
          title: '里程碑负责人',
          dataIndex: 'milestoneLeaderName'
        },
        {
          title: '里程碑进度',
          dataIndex: 'milestoneProgressValue',
          scopedSlots: { customRender: 'milestoneProgressValue' },
          width: 200
        },
        {
          title: '关联项目',
          dataIndex: 'projectName',
          scopedSlots: { customRender: 'projectName' }
        },
        {
          title: '里程碑截止日期',
          dataIndex: 'closingDate',
          scopedSlots: { customRender: 'closingDate' }
        },
        {
          title: '持续时间（天）',
          dataIndex: 'actualDurationDays',
          scopedSlots: { customRender: 'actualDurationDays' }
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      linkShow: false,
      filesShow: false,
      filterForm: {
        queryParam: {},
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        milestoneName: [
          { required: true, message: '请输入里程碑名称', trigger: 'blur' }
        ],
        milestoneStatus: [
          { required: true, message: '请选择里程碑状态', trigger: 'change' }
        ],
        projectId: [
          { required: true, message: '请选择关联项目', trigger: 'change' }
        ]
      },
      projectList: [],
      principalList: [],
      checkOptions: [
          { label: '计划内', value: 1 },
          { label: '滞后', value: 2 },
          { label: '提前', value: 3 }
      ],
      color: {
        1: 'font-c3',
        2: 'red',
        3: 'green',
      },
      memberList: []
    }
  },
  mounted () {
    this.getList()
    this.getProject()
    this.getPrincipal()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getMilestoneList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteMilestone({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createMilestone(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        queryParam: {},
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    getProject () {
      getProjectList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.projectList = res.data.list
      })
    },
    getPrincipal () {
      // getStaffList({
      //   pageNum: 1,
      //   pageSize: 10000
      // }).then(res => {
      //   this.principalList = res.data.list
      // })
      getMilestonePrincipal().then(res => {
        this.principalList = res.data
      })
    },
    projectChange () {
      this.form.milestoneLeaderId = undefined
      getProjectMember({
        projectId: this.form.projectId
      }).then(res => {
        this.memberList = res.data
      })
    },
    rangeChange (date, dateString) {
      this.filterForm.queryParam.beginClosingDate = dateString[0]
      this.filterForm.queryParam.endClosingDate = dateString[1]
    },
    dateChange (date, dateString) {
      this.form.closingDate = dateString
    },
    forceUpdate () {
      this.$forceUpdate()
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
